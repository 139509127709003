<template>
    <DropdownMenuCheckboxItem
        v-bind="forwarded"
        :class=" cn(
            'relative flex cursor-default select-none items-center rounded p-2 gap-2 pr-8 text-sm outline-none transition-colors',
            !props.disabled && 'focus:bg-gray-100 focus:text-gray-900',
            !props.disabled && 'dark:focus:bg-gray-800 dark:focus:text-gray-50',
            'data-[disabled]:opacity-50 data-[disabled]:active:pointer-events-none',
            props.class,
        )"
    >
        <slot />
        <span class="absolute right-2 flex size-3.5 items-center justify-center">
            <DropdownMenuItemIndicator>
                <Check class="size-4" />
            </DropdownMenuItemIndicator>
        </span>
    </DropdownMenuCheckboxItem>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { DropdownMenuCheckboxItem, DropdownMenuItemIndicator, useForwardPropsEmits } from 'radix-vue';
    import { Check } from 'lucide-vue-next';

    import cn from '@aspect/shared/utils/cn.ts';

    import type { HTMLAttributes } from 'vue';
    import type { DropdownMenuCheckboxItemEmits, DropdownMenuCheckboxItemProps } from 'radix-vue';

    const props = defineProps<DropdownMenuCheckboxItemProps & { class?: HTMLAttributes['class'] }>();
    const emits = defineEmits<DropdownMenuCheckboxItemEmits>();

    const delegatedProps = computed(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { class: _, ...delegated } = props;

        return delegated;
    });

    const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>
