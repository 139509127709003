<template>
    <DropdownMenuCheckboxItem
        v-tippy="{ content: disabled ? disabledReason : '', trigger: 'mouseenter' }"
        :class="cn(
            danger && !isDisabled && 'focus:bg-red-500 focus:text-red-50',
            props.class,
        )"
        :disabled="isDisabled"
        v-bind="forwarded"
        @select="onSelect"
    >
        <slot />
        <div
            :class="[
                'pointer-events-none absolute inset-0 flex items-center justify-center transition',
                loading ? 'opacity-100' : 'opacity-0',
            ]"
        >
            <AspectSpinner :light="false" class="size-5" />
        </div>
    </DropdownMenuCheckboxItem>
</template>

<script lang="ts" setup>
    import { directive as vTippy } from 'vue-tippy';
    import { computed } from 'vue';
    import { useForwardPropsEmits } from 'radix-vue';

    import cn from '@aspect/shared/utils/cn.ts';

    import AspectSpinner from './aspect-spinner.vue';
    import { DropdownMenuCheckboxItem } from './ui/dropdown-menu';

    import type { HTMLAttributes } from 'vue';
    import type { DropdownMenuCheckboxItemEmits, DropdownMenuCheckboxItemProps } from 'radix-vue';

    const props = withDefaults(defineProps<DropdownMenuCheckboxItemProps & {
        class?: HTMLAttributes['class'],
        danger?: boolean,
        loading?: boolean,
        autoClose?: boolean,
        disabledReason?: string,
    }>(), {
        autoClose: false,
    });
    const emits = defineEmits<DropdownMenuCheckboxItemEmits>();

    const delegatedProps = computed(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { class: _, disabled, disabledReason, ...delegated } = props;

        return delegated;
    });

    const isDisabled = computed(() => props.disabled || props.loading);

    const forwarded = useForwardPropsEmits(delegatedProps, emits);

    function onSelect(event: Event) {
        if (!props.autoClose) {
            event.preventDefault();
        }
    }
</script>
